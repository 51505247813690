import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  Show,
  SimpleShowLayout,
  Responsive,
  ReferenceManyField,
  GET_LIST,
  Filter,
  TextInput
} from "react-admin";
import { restClient, authClient } from "ra-data-feathers";
import feathersClient from "../../client";
import Box from "../../components/box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DataTable from "react-data-table-component";
import Chart from "react-apexcharts";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const styles = {
  flex: { display: "flex" },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "2em"
  },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em", marginLeft: "1em" },
  rightCol: { flex: 1, marginLeft: "1em", marginRight: "1em" },
  singleCol: {
    marginTop: "2em",
    marginBottom: "2em",
    marginLeft: "1em",
    marginRight: "1em",
    display: "flex",
    flex: 1
  }
};

const restClientOptions = {
  id: "id", // In this example, the database uses '_id' rather than 'id'
  usePatch: true // Use PATCH instead of PUT for updates
};

const authClientOptions = {
  usernameField: "email",
  permissionsField: "userroles",
  storageKey: "feathers-jwt",
  authenticate: { strategy: "local" }
};

const columns = [
  {
    name: "created_at",
    selector: "created_at"
  },
  {
    selector: "costPerMonth",
    name: "costPerMonth"
  },
  {
    selector: "advertisers",
    name: "advertisers"
  },
  {
    selector: "seoDifficulty",
    name: "seoDifficulty"
  },
  {
    selector: "broadCpc",
    name: "broadCpc"
  },
  {
    selector: "phraseCpc",
    name: "phraseCpc"
  },
  {
    selector: "exactCpc",
    name: "exactCpc"
  },
  {
    selector: "globalSearches",
    name: "globalSearches"
  },
  {
    selector: "localSearches",
    name: "localSearches"
  },
  {
    selector: "clicksPerMonth",
    name: "clicksPerMonth"
  },
  {
    selector: "minClicksPerMonth",
    name: "minClicksPerMonth"
  },
  {
    selector: "broadCtr",
    name: "broadCtr"
  },
  {
    selector: "phraseCtr",
    name: "phraseCtr"
  },
  ,
  {
    selector: "exactCtr",
    name: "exactCtr"
  }
];

const dataProvider = restClient(feathersClient, restClientOptions);

const groupBy = key => array => {
  const arr = [];
  return array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    arr.push(value);
    objectsByKeyValue["name"] = key;
    objectsByKeyValue["data"] = arr;
    objectsByKeyValue["type"] = "column";
    return objectsByKeyValue;
  }, {});
};

const ShowLayout = ({ record }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      getResult();
    }
  }, []);

  let broadCpc = groupBy("broadCtr")(data);
  broadCpc["type"] = "line";

  const formattedData =
    data.length > 0
      ? [broadCpc, groupBy("advertisers")(data), groupBy("seoDifficulty")(data)]
      : [];

  console.log(formattedData);

  const getResult = async () => {
    const res = await dataProvider(GET_LIST, "keywords", {
      pagination: [0, 1],
      sort: "id",
      filter: { keyword: record.keyword }
    });
    setData(res.data);
  };

  const chartOptions = {
    options: {
      stroke: {
        width: [0, 4]
      },
      title: {
        text: "History"
      },
      // labels: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct"
        ]
      },
      yaxis: [
        {
          title: {
            text: "Website Blog"
          }
        },
        {
          opposite: true,
          title: {
            text: "Social Media"
          }
        }
      ]
    }
  };

  return (
    <Responsive
      medium={
        <div style={styles.container}>
          <div style={styles.singleCol}>
            <Box title="Keyword" value={record.keyword} />
          </div>
          <div style={styles.leftCol}>
            <div style={styles.flex}>
              <Box
                title="Monthly Searches (LOCAL)"
                value={record.localSearches}
              />
              <Box
                title="Monthly Searches (GLOBAL)"
                value={record.globalSearches}
              />
            </div>
          </div>
          <div style={styles.rightCol}>
            <div style={styles.flex}>
              <Box title="seoDifficulty" value={record.seoDifficulty} />
            </div>
          </div>
          <div style={styles.leftCol}>
            <div style={styles.flex}>
              <Box title="Advertisers" value={record.advertisers} />
              <Box title="BroadCpc" value={record.broadCpc} />
            </div>
          </div>
          <div style={styles.rightCol}>
            <div style={styles.flex}>
              <Box title="Clicks Per Month" value={record.clicksPerMonth} />
              <Box title="BroadCtr" value={record.broadCtr} />
            </div>
          </div>
          <div style={styles.leftCol}>
            <div style={styles.flex}>
              <Box title="costPerMonth" value={record.costPerMonth} />
              <Box title="exactCpc" value={record.exactCpc} />
            </div>
          </div>
          <div style={styles.rightCol}>
            <div style={styles.flex}>
              <Box title="exactCtr" value={record.exactCtr} />
              <Box title="minClicksPerMonth" value={record.minClicksPerMonth} />
            </div>
          </div>
          <div style={styles.singleCol}>
            {/* <Chart
              options={chartOptions.options}
              series={formattedData}
              type="bar"
              height="350"
              width="700"
            /> */}

            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="advertisers"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="broadCtr" />
              <Line type="monotone" dataKey="seoDifficulty" />
              <Line type="monotone" dataKey="broadCpc" />
              <Line type="monotone" dataKey="clicksPerMonth" />
              <Line type="monotone" dataKey="costPerMonth" />
              <Line type="monotone" dataKey="exactCpc" />
              <Line type="monotone" dataKey="exactCtr" />
            </LineChart>
          </div>
          {/* <div style={styles.singleCol}>
            {data.length && (
              <DataTable
                maxWidth="100vw"
                title=""
                columns={columns}
                data={data}
              />
            )}
          </div> */}
        </div>
      }
    />
  );
};

export const KeywordShow = props => (
  <Show {...props}>
    <ShowLayout />
  </Show>
);

const KeywordFilter = props => (
  <Filter {...props}>
    <TextInput label="Keyword" source="keyword" alwaysOn />
  </Filter>
);

export const KeywordList = (props: any) => (
  // @ts-ignore
  <List {...props} title="Keywords" filters={<KeywordFilter />}>
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="Keyword" source="keyword" />
      <TextField label="Cost Per Month" source="costPerMonth" />
      <TextField label="Advertisers" source="advertisers" />
      <TextField label="Difficulty" source="seoDifficulty" />
      <TextField label="Broad Cpc" source="broadCpc" />
      <TextField label="Phrase Cpc" source="phraseCpc" />
      <TextField label="Exact Cpc" source="exactCpc" />
      <TextField label="GLobal Searches" source="globalSearches" />
      <TextField label="Local Searches" source="localSearches" />
      <TextField label="Clicks Per Month" source="clicksPerMonth" />
      <TextField label="Min Clicks Per Month" source="minClicksPerMonth" />
      <TextField label="Broad Ctr" source="broadCtr" />
      <TextField label="Phrase Ctr" source="phraseCtr" />
      <TextField label="Exact Ctr" source="exactCtr" />

      <ShowButton />
    </Datagrid>
  </List>
);
