import React from "react";

//@ts-ignore
import { Admin, Resource } from "react-admin";
//@ts-ignore
import { restClient, authClient } from "ra-data-feathers";
import feathersClient from "./client";
import { VideoList, VideoShow } from "./screens/videos";
import { KeywordList, KeywordShow } from "./screens/keywords";
import Dashboard from "./screens/dashboard";

const restClientOptions = {
  id: "id", // In this example, the database uses '_id' rather than 'id'
  usePatch: true // Use PATCH instead of PUT for updates
};

const authClientOptions = {
  usernameField: "email",
  permissionsField: "userroles",
  storageKey: "feathers-jwt",
  authenticate: { strategy: "local" }
};

const App = () => (
  <Admin
    title="VAS"
    dashboard={Dashboard}
    dataProvider={restClient(feathersClient, restClientOptions)}
    authProvider={authClient(feathersClient, authClientOptions)}
  >
    <Resource name="videos" list={VideoList} show={VideoShow} />
    <Resource name="keywords" list={KeywordList} show={KeywordShow} />
  </Admin>
);
export default App;
