import React, { Component } from "react";
import { GET_LIST, GET_MANY, Responsive, withDataProvider } from "react-admin";
import compose from "recompose/compose";
import { connect } from "react-redux";

// import Welcome from "./Welcome";
import Keywords from "./components/keywords";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" }
};

class Dashboard extends Component {
  state = {};

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <Responsive
        xsmall={
          <div>
            <div style={styles.flexColumn}>
              <div style={{ marginBottom: "2em" }}>
                <span>Welcome</span>
              </div>
              <div style={styles.flex}>
                <Keywords value={50} />
                <Keywords value={50} />
              </div>
              <div style={styles.singleCol}>
                <Keywords value={50} />
              </div>
            </div>
          </div>
        }
        small={
          <div style={styles.flexColumn}>
            <div style={styles.singleCol}>
              <span>Welcome</span>
            </div>
            <div style={styles.flex}>
              <Keywords value={50} />
              <Keywords value={50} />
            </div>
            <div style={styles.singleCol}>
              <Keywords value={50} />
            </div>
          </div>
        }
        medium={
          <div style={styles.flex}>
            <div style={styles.leftCol}>
              <div style={styles.flex}>
                <Keywords value={50} />
                <Keywords value={50} />
              </div>
              <div style={styles.singleCol}>
                <span>Welcome</span>
              </div>
              <div style={styles.singleCol}>
                <Keywords value={50} />
              </div>
            </div>
            <div style={styles.rightCol}>
              <div style={styles.flex}>
                <Keywords value={50} />
                <Keywords value={50} />
              </div>
            </div>
          </div>
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  version: state.admin.ui.viewVersion
});

export default compose(
  connect(mapStateToProps),
  withDataProvider
)(Dashboard);
