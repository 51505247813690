import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Show,
  SimpleShowLayout,
  Responsive,
  ShowButton,
  BooleanField,
  UrlField,
  NumberField,
  FunctionField,
  ArrayField,
  TabbedShowLayout,
  Tab,
  Filter,
  TextInput,
  SelectInput,
  BooleanInput
} from "react-admin";
import Button from "@material-ui/core/Button";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Toolbar from "@material-ui/core/Toolbar";
import YouTube from "react-youtube";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" }
};

// @ts-ignore
const VideoPagination = ({ page, perPage, total, setPage }) => {
  return (
    <Toolbar>
      {/* 
      // @ts-ignore */}
      <Button
        color="primary"
        key="prev"
        icon={<ChevronLeft />}
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
      >
        Prev
      </Button>
      {/* 
      // @ts-ignore */}
      <Button
        color="primary"
        key="next"
        icon={<ChevronRight />}
        onClick={() => setPage(page + 1)}
        labelPosition="before"
      >
        Next
      </Button>
    </Toolbar>
  );
};

export const VideoShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField label="Id" source="id" />
        <TextField source="videoTitle" />
        <DateField source="datePublished" />
        <FunctionField
          label="Video"
          render={record => <YouTube videoId={record.videoId} />}
        />
      </Tab>
      <Tab label="Miscellaneous">
        <TextField source="type" />
        <TextField label="Youtube Channel ID" source="channelId" />
        <TextField source="searchTerm" />
        <NumberField source="views" />
        <NumberField source="dislikeCount" />
        <NumberField source="likeCount" />
        <NumberField source="commentCount" />
        <BooleanField label="Paid" source="paid" />
        <BooleanField label="Unlisted" source="unlisted" />
      </Tab>
      <Tab label="Ads" path="ads">
        <ArrayField source="ads">
          <Datagrid>
            <TextField source="id" />
            <FunctionField
              label="Video"
              render={record => <a href={record.midrollUrl}>Midroll Url</a>}
            />
            <NumberField source="startTime"></NumberField>
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const VideoFilter = props => (
  <Filter {...props}>
    <TextInput label="Title" source="videoTitle" />
    <TextInput label="Search Term" source="searchTerm" />
    <SelectInput
      source="type"
      allowEmpty={false}
      type="name"
      choices={[{ id: "asset", name: "Asset" }, { id: "ad", name: "Ad" }]}
    />
    <BooleanInput label="Paid?" source="paid" />
    <BooleanInput label="Unlisted?" source="unlisted" />
  </Filter>
);

export const VideoList = props => (
  // @ts-ignore
  <List
    {...props}
    title="Videos"
    filters={<VideoFilter />}
    sort={{ field: "created_at", order: "DESC" }}
    pagination={<VideoPagination />}
  >
    <Datagrid expand={<VideoShow />}>
      <DateField label="Published Date" source="datePublished" />
      <TextField label="Video Title" source="videoTitle" />

      <TextField label="Youtube Channel ID" source="channelId" />
      <NumberField source="views" />
      <NumberField source="dislikeCount" />
      <NumberField source="likeCount" />
      <NumberField source="commentCount" />

      <BooleanField label="Paid" source="paid" />
      <BooleanField label="Unlisted" source="unlisted" />
      <TextField source="searchTerm" />
      <DateField label="Created At" source="created_at" />
      <ShowButton />
      {/* <EditButton /> */}
    </Datagrid>
  </List>
);
