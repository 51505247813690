import feathers from "@feathersjs/client";
import auth from "@feathersjs/authentication-client";

export const host = "https://vas-backend.herokuapp.com";
export const authOptions = { jwtStrategy: "jwt", storage: window.localStorage };

export default feathers()
  .configure(feathers.rest(host).fetch(window.fetch.bind(window)))
  // @ts-ignore
  .configure(auth(authOptions));
