import React from "react";
import compose from "recompose/compose";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { translate } from "react-admin";

const styles = {
  main: {
    flex: "1",
    marginLeft: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "center",
    padding: 16,
    minHeight: 52
  }
};
const Box = ({ title, value, translate, classes }) => (
  <div className={classes.main}>
    <Card className={classes.card}>
      <Typography className={classes.title} color="textSecondary">
        {title}
      </Typography>
      <Typography variant="headline" component="h2">
        {value}
      </Typography>
    </Card>
  </div>
);

const enhance = compose(
  withStyles(styles),
  translate
);
export default enhance(Box);
